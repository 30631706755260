import { useAllCertifications } from 'gatsby/graphql/useExternalCertifications';
import React from 'react';
import CertificationLogo from './CertificationLogo';

const ExternalCertificationsBanner: React.FC = () => {
  const certs = useAllCertifications();
  return (
    <div className={'grid grid-cols-2 gap-rhythm3 py-rhythm1 xl:grid-cols-4'}>
      {certs.map(c => {
        return (
          <CertificationLogo
            key={c.name}
            data={c}
            className="flex max-h-rhythm4 w-full"
            imgClassName="w-full"
          />
        );
      })}
    </div>
  );
};

export default ExternalCertificationsBanner;
