import {
  CdnImageDataFragment,
  ProductCategoryQuery
} from 'gatsby/graphqlTypes';
import { Heading, InterfaceText, TextSize } from 'components/atoms/typography';
import { Link, graphql } from 'gatsby';

import { AppPageProps } from 'gatsby/types';
import Balancer from 'react-wrap-balancer';
import CategoryHeader from 'components/molecules/banners/CategoryHeader';
import { Container } from 'components/atoms/layout/Container';
import ExternalCertificationsBanner from 'components/molecules/banners/certifications/CertificationsBanner';
import PageContentLayout from 'components/layouts/PageContentLayout';
import { ProductList } from 'components/organisms/product-list/List';
import React from 'react';
import { temporaryAllCategoryNavItems } from 'lib/navigation/categories';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query ProductCategory($strapiId: Int!) {
    c: strapiProductCategory(strapiId: { eq: $strapiId }) {
      bannerImage {
        landscape {
          ...CdnImageData
        }
      }
      name
      subheading
      description
      descriptionSlate {
        ...SlateContent
      }
      children: strapiChildren {
        name
        pageMetadata {
          slug
        }
      }
      products: recursiveProducts {
        ...StrapiProductCard
      }
    }
    # all: allStrapiProductCategory {
    #   nodes {
    #     name
    #   }
    # }
  }
`;

type Props = AppPageProps<ProductCategoryQuery>;

const ProductCategoryPage: React.FC<Props> = ({ data, pageContext }) => {
  const category = data.c;
  if (!category) {
    throw Error('Missing data');
  }

  const { name, bannerImage, subheading, descriptionSlate, products } =
    category;

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <CategoryHeader
        title={name}
        subtitle={subheading}
        description={descriptionSlate}
        image={bannerImage?.landscape as CdnImageDataFragment}
      />
      <Container className="py-rhythm6">
        <ProductList products={products} />
        <div className="mt-rhythm6 flex flex-col items-center justify-center bg-pearl-400 pb-rhythm3">
          <Heading level={2} size={TextSize.GreatPrimer}>
            Browse
          </Heading>
          <div className="flex flex-col space-y-rhythm0 pt-rhythm0 lg:flex-row lg:space-x-3 lg:space-y-0">
            {temporaryAllCategoryNavItems.map(cat => (
              <Link
                key={cat.name}
                to={cat.href}
                activeClassName="bg-primary-200 cursor-default pointer-events-none"
                className="rounded-full border-4 border-primary px-5 py-2"
              >
                <InterfaceText>
                  <Balancer>{cat.name}</Balancer>
                </InterfaceText>
              </Link>
            ))}
          </div>
        </div>
        <div className="pt-rhythm6">
          <ExternalCertificationsBanner />
        </div>
      </Container>
    </PageContentLayout>
  );
};

export default ProductCategoryPage;
