export type ResizeProps = {
  height: number;
  width: number;
  newWidth: number;
};

// (original height / original width) x new width = new height
export function resizeToWidth({ height, width, newWidth }: ResizeProps) {
  return (height / width) * newWidth;
}
