import { Heading, InterfaceText, TextSize } from 'components/atoms/typography';
import SlateContent, { SlateContentParsed } from '../cms/SlateContent';

import Balancer from 'react-wrap-balancer';
import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
import { Container } from 'components/atoms/layout/Container';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import Ridges from 'components/atoms/images/ridges';
import { makeClassNames } from 'lib/util';

type CategoryHeaderProps = PropsWithClassName &
  Readonly<{
    title: string;
    subtitle?: string | null;
    image: CdnImageDataFragment;
    description?: SlateContentParsed;
  }>;

// Inspired by https://www.technologyreview.com/2023/02/14/1067869/rust-worlds-fastest-growing-programming-language/

const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  className,
  title,
  subtitle,
  description,
  image
}) => {
  return (
    <div className={makeClassNames('relative', className)}>
      {/* Heading dark background */}
      <div className="absolute inset-0 h-rhythm11 bg-primary-700" />
      {/* Ridges */}
      <div className="absolute right-0 z-10 h-rhythm11 pt-rhythm2">
        <span className="lg:hidden">
          <Ridges />
        </span>
        <span className="hidden lg:inline">
          <Ridges size="lg" />
        </span>
      </div>
      <Container
        className={makeClassNames(
          'relative z-20 flex flex-col  items-center pt-rhythm2 lg:pt-rhythm4'
        )}
      >
        <div className="w-full pb-rhythm1 text-pearl-500 lg:flex-1">
          <Heading
            level={1}
            color=""
            bold
            withoutSpacing
            className="pb-rhythm1"
            size={TextSize.DoublePica}
          >
            <Balancer>{title}</Balancer>
          </Heading>
          {subtitle && (
            <InterfaceText
              role="doc-subtitle"
              className="block"
              color=""
              size={TextSize.Pica}
            >
              <Balancer>{subtitle}</Balancer>
            </InterfaceText>
          )}
        </div>
        <div
          className={makeClassNames(
            'relative h-rhythm12 w-full rounded drop-shadow-xl'
          )}
        >
          {!!description && (
            <div className="absolute bottom-0 z-20 flex min-h-1/2 w-full items-center justify-center bg-pearl-300 bg-opacity-80 p-4">
              <SlateContent
                measure="measure-wide"
                compact
                content={description}
              />
            </div>
          )}
          <ResponsiveImage
            className="rounded"
            eager
            data={image}
            display="block lg:hidden"
          />
          <ResponsiveImage
            className="rounded"
            eager
            data={image}
            display="hidden lg:block"
          />
        </div>
      </Container>
    </div>
  );
};

export default CategoryHeader;
