import React from 'react';
import { makeClassNames } from 'lib/util';
import { resizeToWidth } from 'lib/image';

export type LogoData = {
  image: {
    alternativeText: string;
    url: string;
    width: number;
    height: number;
  };
  name: string;
  url?: string;
};

type ExternalCertificationLogoProps = PropsWithClassName &
  Readonly<{
    data: LogoData;
    imgClassName?: string;
    width?: number;
  }>;

const ExternalCertificationLogo: React.FC<ExternalCertificationLogoProps> = ({
  data,
  className,
  imgClassName,
  width
}) => {
  const containerClassName = makeClassNames(className, 'flex items-center');
  const logoClassName = makeClassNames(imgClassName, 'max-h-full');

  const { url, image } = data;

  let imageEl = null;
  if (image) {
    const imgWidth = width || (image.width as number);
    const imgHeight = width
      ? resizeToWidth({
          width: image.width as number,
          height: image.height || 0,
          newWidth: width
        })
      : (image.width as number);

    imageEl = (
      <img
        className={logoClassName}
        loading="lazy"
        src={image.url}
        alt={image.alternativeText || ''}
        width={imgWidth}
        height={imgHeight}
      />
    );
  }

  if (url && image) {
    return (
      <a
        key={data.name}
        className={containerClassName}
        target="_blank"
        href={url}
      >
        {imageEl}
      </a>
    );
  }

  return (
    <div key={data.name} className={containerClassName}>
      {imageEl}
    </div>
  );
};

export default ExternalCertificationLogo;
