import { graphql, useStaticQuery } from 'gatsby';

import type { ExternalCertificationsQuery } from '../graphqlTypes';
import { makeStrapiLink } from '../../lib/util';

// Strapi does not pass enum data type to GraphQL Enum.
// So this type is handled manually
export type ExternalCertificationName =
  | 'ESG'
  | 'One_Percent_for_the_Planet'
  | 'PETA'
  | 'Made_in_Britain';

export type ExternalCertification = {
  url?: string;
  name: ExternalCertificationName;
  image: {
    alternativeText: string;
    url: string;
    width: number;
    height: number;
  };
};

export function useAllCertifications(): ExternalCertification[] {
  const { data }: ExternalCertificationsQuery = useStaticQuery(graphql`
    query ExternalCertifications {
      data: strapiSiteConfig {
        items: externalCertifications {
          url: link
          name
          image: icon {
            alternativeText
            url
            width
            height
          }
        }
      }
    }
  `);

  return data?.items.map(item => {
    /**
     * SVG in Strapi doesn't include dimensions by default
     * https://github.com/strapi/strapi/issues/12823
     * Workaround is to add manually
     */
    if (!item?.image?.width || !item?.image?.height) {
      throw Error('Missing icon dimensions');
    }
    return {
      ...item,
      image: {
        ...item?.image,
        url: makeStrapiLink(item?.image?.url)
      }
    };
  }) as ExternalCertification[];
}

export function useExternalCertifications() {
  const certs = useAllCertifications();
  return certs.filter(cert => !!cert.url);
}

export function usePETALink(): string {
  const certs = useExternalCertifications();
  const peta = certs.find(c => c.name === 'PETA');
  if (!peta) {
    throw Error('No PETA data');
  }

  return peta.url as string;
}
