import React from 'react';
import StrapiProductCard from '../product/ProductCard';
import { StrapiProductCardFragment } from 'gatsby/graphqlTypes';
import { makeClassNames } from 'lib/util';

type Props = {
  cardSize?: 'small' | 'large';
  className?: string;
  products: StrapiProductCardFragment[];
};

const largeClassNames = ['lg:grid-cols-2', '2xl:grid-cols-3'];
const smallClassNames = ['lg:grid-cols-2', '2xl:grid-cols-4'];

export const ProductList: React.FC<Props> = ({
  cardSize = 'large',
  className,
  products
}) => {
  return (
    <section aria-labelledby="product-heading" className={className}>
      <h2 id="product-heading" className="sr-only">
        Products
      </h2>

      <div
        className={makeClassNames(
          'grid grid-cols-1 gap-y-rhythm1 gap-x-rhythm0 md:gap-rhythm3',
          cardSize === 'large' ? largeClassNames : smallClassNames
        )}
      >
        {products.map(product => (
          <StrapiProductCard responsive key={product.id} data={product} />
        ))}
      </div>
    </section>
  );
};
